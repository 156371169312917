import React, { FunctionComponent } from 'react'
import Layout from '../components/Layout'
import {
  MapComponent,
  StringToLatLng,
  tracksData,
} from '../components/MapComponent'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

export interface contentTemplateProps {
  data: {
    allWpPage: {
      edges: {
        node: {
          id: string
          title: string
          databaseId: number
          gPS: string
          bikeRide: string[]
          slug: string
          content: string
          dateArrived: string
          dateLeft: string
          featuredImage: {
            node: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: IGatsbyImageData
                }
              }
            }
          }
        }
      }[]
    }
    allWpMediaItem: {
      nodes: tracksData[]
    }
  }
  pageContext: {
    countryLink: string
    id: number
    zoom: number
    gps: string
    trackId: number
  }
}

const MapContentTemplate: FunctionComponent<contentTemplateProps> = (props) => {
  const { data, pageContext } = props
  const towns = data.allWpPage.edges.map((el) => ({
    ...el,
    countryLink: props.pageContext.countryLink,
  }))

  return (
    <Layout>
      <MapComponent
        data={towns}
        zoom={props.pageContext.zoom}
        center={StringToLatLng(props.pageContext.gps)}
        single={true}
        tracksData={data.allWpMediaItem.nodes}
      ></MapComponent>
    </Layout>
  )
}

export default MapContentTemplate

export const query = graphql`
  query($id: Int, $trackId: Int) {
    allWpPage(filter: { databaseId: { eq: $id } }) {
      edges {
        node {
          id
          title
          slug
          databaseId
          gPS
          content
          dateArrived
          dateLeft
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 450, height: 450, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    allWpMediaItem(
      filter: {
        localFile: { extension: { eq: "gpx" } }
        parentDatabaseId: { eq: $trackId }
      }
    ) {
      nodes {
        localFile {
          extension
          absolutePath
        }
        mediaItemUrl
      }
    }
  }
`
